<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-06-30 11:09:36
 * @ Description: Overlay to select and confirm Fixer subscription package.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="showPaymentOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        width="850px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="500px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                >
                    <!-- Selected Package Details -->
                    <v-col
                        cols="5"
                        md="4"
                        style="background-color: #F9FBFD; display: flex; align-items: center;"
                    >
                        <v-card
                            class="mx-auto text-center py-3"
                            elevation="1"
                            :width="$vuetify.breakpoint.lgAndUp ? '255' : '285'"
                            :max-width="$vuetify.breakpoint.lgAndUp ? '255' : '285'"
                        >
                            <v-row
                                style="width: 100%;"
                                class="mx-auto"
                            >
                                <v-col
                                    cols="12"
                                    class="os-27-sb"
                                    style="background: rgba(243, 170, 99, 0.11); height: 86px; color: #2E567A; display: flex; align-items: center; justify-content: center;"
                                >
                                    <div
                                        style="max-width: 100%; line-height: 1.25; "
                                        class="text-truncate"
                                    >
                                        {{ invoiceData.item.name }}
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center px-2 py-4 os-14-r"
                                    style="color: #2E567A;"
                                    v-html="invoiceData.item.topText"
                                />
                                <v-col
                                    class="py-0 text-center"
                                    style="display: flex; align-items: center; justify-content: center; color: #F3AA63; height: 60px; overflow-y: auto; font-weight: 600 !important; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important; font-size: 38px;"
                                    id="fittext"
                                >
                                    <span v-if="invoiceData.item.subscription_id === 4 || invoiceData.item.subscription_id === 8">Per Action</span>
                                    <span v-else>{{ invoiceData.item.symbol }}{{ Number(invoiceData.item.price).toFixed(2) }}</span>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center px-2 py-4 os-14-r"
                                    style="color: #2E567A;"
                                >
                                    {{ invoiceData.item.bottomText }}
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- Selected Package Details -->

                    <v-col
                        cols="7"
                        md="8"
                        class="px-md-6 py-6"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="os-18-sb"
                                style="color: #222B45;"
                            >
                                Choose Payment Method
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>

                            <!-- Add Card Button -->
                            <v-col
                                cols="12"
                                class="text-right"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="createCard()"
                                >
                                    Add Card
                                    <v-icon
                                        class="ml-1"
                                    >
                                        {{ mdiPlusCircleOutline }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <!-- Add Card Button -->

                            <!-- No Profile Cards -->
                            <v-col
                                v-if="profileCards === null || profileCards.length === 0"
                                class="os-12-r text-center"
                                style="color: rgba(0, 0, 0, 0.38);"
                                cols="12"
                            >
                                No cards added
                            </v-col>
                            <!-- No Profile Cards -->

                            <!-- Profile Cards -->
                            <v-col
                                cols="12"
                                class="pt-0 mt-n3"
                                v-else
                            >
                                <v-radio-group
                                    v-model="selectedCard"
                                >
                                    <v-radio
                                        v-for="(item, index) in profileCards"
                                        :key="index"
                                        :value="item.id"
                                        color="#2E567A"
                                        :ripple="false"
                                    >
                                        <template v-slot:label>
                                            <v-row
                                                align="center"
                                            >
                                                <v-col
                                                    cols="2"
                                                >
                                                    <v-img
                                                        :src="item.brand === 'VISA' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountVisaLogo.png' : item.brand === 'MASTERCARD' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountMastercardLogo.png' : item.brand === 'AMEX' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountAmexLogo.png' : '/dashboard/profile/general/account/DashboardProfileGeneralAccountGenericCardLogo.png'"
                                                        height="25px"
                                                        width="40px"
                                                        contain
                                                    />
                                                </v-col>
                                                <v-col
                                                    cols="10"
                                                    class="pb-2"
                                                >
                                                    <div
                                                        class="os-16-sb"
                                                    >
                                                        ****{{ item.number }}
                                                    </div>
                                                    <div
                                                        class="os-12-r pt-0"
                                                        style="color: #707070;"
                                                    >
                                                        {{ item.expiry.substring(0, 2) }}/{{ item.expiry.substring(2) }}
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    class="pt-0"
                                                >
                                                    <v-divider />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                            <!-- Profile Cards -->

                            <!-- Purchase Summary -->
                            <v-col
                                cols="12"
                                class="os-18-sb"
                                style="color: #222B45;"
                            >
                                Purchase Summary
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="8"
                                class="os-18-sb"
                            >
                                {{ invoiceData.invoice.qty }} x {{ invoiceData.invoice.description }}
                            </v-col>
                            <v-col
                                cols="4"
                                class="os-18-sb text-right"
                            >
                                {{ invoiceData.item.symbol }}{{ Number(invoiceData.invoice.price).toFixed(2) }}
                            </v-col>
                            <!-- Purchase Summary -->
                        </v-row>

                        <!-- Cancel/Proceed Buttons -->
                        <v-row
                            style="height: 20%;"
                        >
                            <v-col
                                cols="12"
                                class="text-right os-13-sb"
                                style="height: 100%;"
                                align-self="end"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="showPaymentOverlay(false)"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="showPaymentOverlay(true)"
                                    :disabled="profileCards === null || profileCards.length === 0"
                                >
                                    Proceed
                                </v-btn>
                            </v-col>
                        </v-row>
                        <!-- Cancel/Proceed Buttons -->
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="showPaymentOverlayBoolean"
        fullscreen
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="500px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                    class="py-4"
                >
                    <!-- Selected Package Details -->
                    <v-col
                        cols="12"
                        class="pb-6"
                        style="background-color: #F9FBFD; display: flex; align-items: center;"
                    >
                        <v-card
                            class="mx-auto text-center py-3"
                            elevation="1"
                            :width="$vuetify.breakpoint.lgAndUp ? '255' : '285'"
                            :max-width="$vuetify.breakpoint.lgAndUp ? '255' : '285'"
                        >
                            <v-row
                                style="width: 100%;"
                                class="mx-auto"
                            >
                                <v-col
                                    cols="12"
                                    class="os-27-sb"
                                    style="background: rgba(243, 170, 99, 0.11); height: 86px; color: #2E567A; display: flex; align-items: center; justify-content: center;"
                                >
                                    <div
                                        style="max-width: 100%; line-height: 1.25; "
                                        class="text-truncate"
                                    >
                                        {{ invoiceData.item.name }}
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center px-2 py-4 os-14-r"
                                    style="color: #2E567A;"
                                    v-html="invoiceData.item.topText"
                                />
                                <v-col
                                    class="py-0 text-center"
                                    style="display: flex; align-items: center; justify-content: center; color: #F3AA63; height: 60px; overflow-y: auto; font-weight: 600 !important; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important; font-size: 38px;"
                                    id="fittext"
                                >
                                    <span v-if="invoiceData.item.subscription_id === 4 || invoiceData.item.subscription_id === 8">Per Action</span>
                                    <span v-else>{{ invoiceData.item.symbol }}{{ Number(invoiceData.item.price).toFixed(2) }}</span>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center px-2 py-4 os-14-r"
                                    style="color: #2E567A;"
                                >
                                    {{ invoiceData.item.bottomText }}
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- Selected Package Details -->

                    <v-col
                        cols="12"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="os-18-sb"
                                style="color: #222B45;"
                            >
                                Choose Payment Method
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>

                            <!-- Add Card Button -->
                            <v-col
                                cols="12"
                                class="text-right"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="createCard()"
                                >
                                    <span v-if="!$store.state.general.customBreakpoints.xxs">Add Card</span>
                                    <v-icon
                                        class="ml-1"
                                    >
                                        {{ mdiPlusCircleOutline }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <!-- Add Card Button -->

                            <!-- No Profile Cards -->
                            <v-col
                                v-if="profileCards === null || profileCards.length === 0"
                                class="os-12-r text-center"
                                style="color: rgba(0, 0, 0, 0.38);"
                                cols="12"
                            >
                                No cards added
                            </v-col>
                            <!-- No Profile Cards -->

                            <!-- Profile Cards -->
                            <v-col
                                cols="12"
                                class="pt-0 mt-n3"
                                v-else
                            >
                                <v-radio-group
                                    v-model="selectedCard"
                                >
                                    <v-radio
                                        v-for="(item, index) in profileCards"
                                        :key="index"
                                        :value="item.id"
                                        color="#2E567A"
                                        :ripple="false"
                                    >
                                        <template v-slot:label>
                                            <v-row
                                                align="center"
                                            >
                                                <v-col
                                                    cols="2"
                                                >
                                                    <v-img
                                                        :src="item.brand === 'VISA' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountVisaLogo.png' : item.brand === 'MASTERCARD' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountMastercardLogo.png' : item.brand === 'AMEX' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountAmexLogo.png' : '/dashboard/profile/general/account/DashboardProfileGeneralAccountGenericCardLogo.png'"
                                                        height="25px"
                                                        width="40px"
                                                        contain
                                                    />
                                                </v-col>
                                                <v-col
                                                    cols="10"
                                                    :class="$store.state.general.customBreakpoints.xxs ? 'pb-0' : ''"
                                                >
                                                    <div
                                                        class="os-16-sb"
                                                    >
                                                        {{ item.brand }}
                                                    </div>
                                                    <div
                                                        class="os-12-r mt-n1"
                                                        style="color: #707070;"
                                                    >
                                                        {{ item.number }}
                                                    </div>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    class="pt-0"
                                                >
                                                    <v-divider />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                            <!-- Profile Cards -->

                            <!-- Purchase Summary -->
                            <v-col
                                cols="12"
                                class="os-18-sb"
                                style="color: #222B45;"
                            >
                                Purchase Summary
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="8"
                                class="os-18-sb"
                            >
                                {{ invoiceData.invoice.qty }} x {{ invoiceData.invoice.description }}
                            </v-col>
                            <v-col
                                cols="4"
                                class="os-18-sb text-right"
                            >
                                {{ invoiceData.item.symbol }}{{ Number(invoiceData.invoice.price).toFixed(2) }}
                            </v-col>
                            <!-- Purchase Summary -->
                        </v-row>

                        <!-- Cancel/Proceed Buttons -->
                        <v-row
                            style="height: 20%;"
                        >
                            <v-col
                                cols="12"
                                class="text-right os-13-sb"
                                style="height: 100%;"
                                align-self="end"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="showPaymentOverlay(false)"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="showPaymentOverlay(true)"
                                    :disabled="profileCards === null || profileCards.length === 0"
                                >
                                    Proceed
                                </v-btn>
                            </v-col>
                        </v-row>
                        <!-- Cancel/Proceed Buttons -->
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    import { mdiPlusCircleOutline } from '@mdi/js'
    import { GeneralProfileCardController } from '@/logic/controller/index.controller.js'
    import { Capacitor } from '@capacitor/core'
    import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser'

    export default {
        name: 'PaymentOverlayComponent',

        props: {
            showPaymentOverlayBoolean: {
                type: Boolean,
                required: true
            },

            invoiceData: {
                type: Object,
                required: true
            }
        },

        watch: {
            showPaymentOverlayBoolean: {
                async handler (value) {
                    if (value) {
                        await this.getProfileCards()
                       
                        this.$nextTick(() => {
                            var fittext = document.getElementById('fittext')
                            fittext.style.fontSize = '38px'
                            this.findFittextFontSize(fittext)
                        })
                    }
                }
            },

            '$vuetify.breakpoint': {
                handler () {
                    this.$nextTick(() => {
                        this.$forceUpdate()
                    })
                },
                deep: true
            }
        },

        data () {
            return {
                selectedCard: 0,
                mdiPlusCircleOutline,
                sessionID: null,
                generalProfileCardController: null,
                profileCards: [],
                cardAdded: false
            }
        },

        beforeMount () {
            this.generalProfileCardController = new GeneralProfileCardController()
        },

        methods: {
            showPaymentOverlay (confirmUpgrade) {
                this.$emit('showPaymentOverlay', false, confirmUpgrade, this.profileCards[this.selectedCard])
            },

            findFittextFontSize (fittext) {
                this.$nextTick(() => {
                    if (fittext.scrollHeight > fittext.clientHeight) {
                        this.$nextTick(() => {
                            var fontSize = fittext.style.fontSize
                            var fontSizeNumber = fontSize.split('px')[0]
                            fittext.style.fontSize = `${Number(fontSizeNumber - 1)}px`

                            this.findFittextFontSize(fittext)
                        })
                    } else if (fittext.scrollWidth > fittext.clientWidth) {
                        this.$nextTick(() => {
                            var fontSize = fittext.style.fontSize
                            var fontSizeNumber = fontSize.split('px')[0]
                            fittext.style.fontSize = `${Number(fontSizeNumber - 1)}px`

                            this.findFittextFontSize(fittext)
                        })
                    }
                })
            },

            async getProfileCards () {
                try {
                    this.profileCards = await this.generalProfileCardController.retrieve()

                    if (!this.profileCards || !this.profileCards.length) await this.createCard()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async createCard () {
                const self = this

                if (Capacitor.getPlatform() === 'android') {
                    try {
                        const paymentURL = await this.generalProfileCardController.createPaymentURL()

                        const inAppBrowser = InAppBrowser.create(paymentURL, '_blank')

                        inAppBrowser.on('loadstop').subscribe(event => {
                            self.onBrowserLoadStop()
                        })

                        inAppBrowser.on('loadstart').subscribe(async event => {
                            self.onBrowserLoadStart(event, inAppBrowser)
                        })

                        inAppBrowser.on('loaderror').subscribe(event => {
                            self.onBrowserError()
                        })

                        inAppBrowser.on('exit').subscribe(event => {
                            self.onBrowserClose()
                        })
                    } catch (error) {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                } else if (Capacitor.getPlatform() === 'web') {
                    try {
                        const payFastUUID = await this.generalProfileCardController.createCardSession()

                        window.payfast_do_onsite_payment(
                            { uuid: payFastUUID }, 
                            async function (result) {
                                if (result === true) {
                                    await self.getProfileCards()
                                }
                                else {
                                    self.$store.commit('setSnackbar', {
                                        text: 'Add Card Cancelled',
                                        show: true
                                    })
                                }
                            })
                    } catch (error) {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            onBrowserLoadStart (event, inAppBrowser) {
                if (event.url.includes('/payment/')) {
                    if (event.url.includes('/success')) {
                        this.cardAdded = true
                        inAppBrowser.close(false)
                    } else if (event.url.includes('/cancelled')) {
                        // this.cardAdded = false
                        inAppBrowser.close(false)
                    }
                } else if (event.url.includes('9to5fixer')) inAppBrowser.close(true)
            },

            onBrowserLoadStop () {
                // this.$store.commit('setSnackbar', {
                //     text: 'onBrowserLoadStop',
                //     show: true
                // })
            },

            onBrowserError () {
                this.$store.commit('setSnackbar', {
                    text: 'Error Creating Card',
                    show: true
                })
            },

            onBrowserClose (error) {
                if (error) {
                    this.$store.commit('setSnackbar', {
                        text: 'PayFast Error',
                        show: true
                    })
                } else {
                    if (this.cardAdded) {
                        this.getProfileCards()
                    } else {
                        this.$store.commit('setSnackbar', {
                            text: 'Add Card Cancelled',
                            show: true
                        })
                    }
                }

                this.cardAdded = false
            }
        }
    }
</script>
<style>
    #payfast_div_container {
        z-index: 2000;
    }
</style>
